// // src/components/Settlement.js

// import React, { useState, useEffect } from 'react';
// import '../styles/Settlement.css';
// import { FiDownload, FiSearch } from 'react-icons/fi'; // Import icons

// const Settlement = () => {
//   // **State Variables**
//   const [settlements] = useState([]); // Holds all settlements data
//   const [filteredSettlements, setFilteredSettlements] = useState([]); // Settlements filtered by search
//   const [currentPage, setCurrentPage] = useState(1); // Current page number for pagination
//   const settlementsPerPage = 10; // Number of settlements per page
//   const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering

//   /**
//    * useEffect Hook
//    *
//    * This hook runs when the component mounts.
//    * Backend Developer: Replace the code inside this hook with an API call to fetch settlements data.
//    */
//   useEffect(() => {
//     // TODO: Fetch settlements data from the backend API and update the 'settlements' state.
//     // Example:
//     // fetch('/api/settlements')
//     //   .then(response => response.json())
//     //   .then(data => {
//     //     setSettlements(data);
//     //     setFilteredSettlements(data);
//     //   })
//     //   .catch(error => {
//     //     console.error('Error fetching settlements:', error);
//     //     // Handle error state if needed
//     //   });

//     // For now, we'll initialize 'filteredSettlements' with an empty array
//     setFilteredSettlements([]);
//   }, []);

//   // **Handle Search Input**
//   const handleSearchChange = (e) => {
//     const term = e.target.value;
//     setSearchTerm(term);
//     filterSettlements(term);
//   };

//   // **Filter Settlements**
//   const filterSettlements = (term) => {
//     let filtered = settlements;

//     // Filter by search term
//     if (term) {
//       filtered = filtered.filter(
//         (settlement) =>
//           settlement.merchantName.toLowerCase().includes(term.toLowerCase()) ||
//           settlement.merchantId.toLowerCase().includes(term.toLowerCase()) ||
//           settlement.status.toLowerCase().includes(term.toLowerCase())
//       );
//     }

//     setFilteredSettlements(filtered);
//     setCurrentPage(1); // Reset to first page when filters change
//   };

//   // **Pagination Logic**
//   const indexOfLastSettlement = currentPage * settlementsPerPage;
//   const indexOfFirstSettlement = indexOfLastSettlement - settlementsPerPage;
//   const currentSettlements = filteredSettlements.slice(
//     indexOfFirstSettlement,
//     indexOfLastSettlement
//   );
//   const totalPages = Math.ceil(filteredSettlements.length / settlementsPerPage);

//   // **Handle Page Change**
//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   // **Handle Download Settlements**
//   const handleDownload = () => {
//     // TODO: Implement actual download logic to export settlements data
//     alert('Download functionality to be implemented.');
//   };

//   return (
//     <div className="settlement-content">
//       {/* Header Section */}
//       <header className="settlement-header">
//         <div className="settlement-title">
//           <h2>Settlement</h2>
//         </div>
//         <div className="date-selector">
//           <label htmlFor="start-date" className="visually-hidden">
//             Start Date
//           </label>
//           <input
//             type="date"
//             id="start-date"
//             aria-label="Start Date"
//             // TODO: Implement date selection functionality
//           />
//           <span> to </span>
//           <label htmlFor="end-date" className="visually-hidden">
//             End Date
//           </label>
//           <input
//             type="date"
//             id="end-date"
//             aria-label="End Date"
//             // TODO: Implement date selection functionality
//           />
//           {/* Backend Developer: Implement date filtering functionality based on selected dates */}
//         </div>
//       </header>

//       {/* Action Bar */}
//       <div className="settlement-action-bar">
//         {/* Placeholder for alignment */}
//         <div className="settlement-tabs"></div>

//         {/* Search and Download */}
//         <div className="settlement-actions">
//           <div className="search-bar">
//             <input
//               type="text"
//               placeholder="Search settlements"
//               value={searchTerm}
//               onChange={handleSearchChange}
//             />
//             <FiSearch className="search-icon" />
//           </div>
//           <button className="download-button" onClick={handleDownload}>
//             <FiDownload className="download-icon" />
//             Download
//           </button>
//         </div>
//       </div>

//       {/* Settlements Table */}
//       <div className="settlement-table-container">
//         <div className="settlement-table-wrapper">
//           <table className="settlement-table">
//             <thead>
//               <tr>
//                 <th>Date</th>
//                 <th>Merchant Name</th>
//                 <th>Merchant ID</th>
//                 <th>Settlement Amount</th>
//                 <th>Status</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentSettlements.length > 0 ? (
//                 currentSettlements.map((settlement) => (
//                   <tr key={settlement.id}>
//                     <td>{settlement.date}</td>
//                     <td>{settlement.merchantName}</td>
//                     <td>{settlement.merchantId}</td>
//                     <td>₹{settlement.amount}</td>
//                     <td>
//                       <span className={`status ${settlement.status.toLowerCase()}`}>
//                         {settlement.status}
//                       </span>
//                     </td>
//                     <td>
//                       {/* Placeholder for actions */}
//                       <button className="action-button">View</button>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="6" className="no-settlements">
//                     <div className="no-settlements-message">
//                       <p>No settlements found.</p>
//                     </div>
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>

//         {/* Pagination */}
//         <div className="pagination">
//           {[...Array(totalPages || 1)].map((_, idx) => (
//             <button
//               key={idx + 1}
//               className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
//               onClick={() => handlePageChange(idx + 1)}
//               disabled={filteredSettlements.length === 0}
//             >
//               {idx + 1}
//             </button>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Settlement;


import React, { useState, useEffect } from 'react';
import '../styles/Settlement.css'; // Import styles for settlement page
import { FiDownload, FiSearch } from 'react-icons/fi'; // Import icons
import { CSVLink } from 'react-csv'; // For CSV download functionality

const Settlement = () => {
  const [settlements, setSettlements] = useState([]); // Holds all settlements data
  const [filteredSettlements, setFilteredSettlements] = useState([]); // Settlements filtered by search and date
  const [currentPage, setCurrentPage] = useState(1); // Current page number for pagination
  const settlementsPerPage = 10; // Number of settlements per page
  const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering
  const [startDate, setStartDate] = useState(''); // Start date for filtering
  const [endDate, setEndDate] = useState(''); // End date for filtering
  const merchantId = localStorage.getItem('merchantId'); // Get merchantId from localStorage

  /**
   * Fetch settlements data from the backend API when the component mounts.
   */
  useEffect(() => {
    fetchSettlements();
    const intervalId = setInterval(fetchSettlements, 5000); // Poll the backend every 5 seconds for real-time updates
    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  /**
   * Function to fetch settlements data from the backend API.
   */
  const fetchSettlements = async () => {
    try {
      const response = await fetch(`https://payin.payinfy.com/api/settlements/${merchantId}`);
      const data = await response.json();
      if (data.status === 'Success') {
        setSettlements(data.settlements);
        setFilteredSettlements(data.settlements); // Initialize filtered settlements
      } else {
        console.error('Error fetching settlements:', data.message);
      }
    } catch (error) {
      console.error('Error fetching settlements:', error);
    }
  };

  /**
   * Handle search input changes and filter settlements based on search term and date.
   */
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    filterSettlements(term, startDate, endDate);
  };

  /**
   * Function to filter settlements by search term and date range.
   */
  const filterSettlements = (term, start, end) => {
    let filtered = settlements;

    // Filter by search term (customer name, payment type)
    if (term) {
      filtered = filtered.filter(
        (settlement) =>
          settlement.customerName.toLowerCase().includes(term.toLowerCase()) ||
          settlement.paymentType.toLowerCase().includes(term.toLowerCase())
      );
    }

    // Filter by date range with time
    if (start && end) {
      filtered = filtered.filter((settlement) => {
        const settlementDate = new Date(settlement.date);
        return settlementDate >= new Date(start) && settlementDate <= new Date(end);
      });
    }

    setFilteredSettlements(filtered);
    setCurrentPage(1); // Reset to the first page when filters change
  };

  /**
   * Handle date changes and filter settlements by date range.
   */
  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    filterSettlements(searchTerm, start, end);
  };

  /**
   * Pagination logic: Calculate current page settlements.
   */
  const indexOfLastSettlement = currentPage * settlementsPerPage;
  const indexOfFirstSettlement = indexOfLastSettlement - settlementsPerPage;
  const currentSettlements = filteredSettlements.slice(indexOfFirstSettlement, indexOfLastSettlement);
  const totalPages = Math.ceil(filteredSettlements.length / settlementsPerPage);

  /**
   * Handle page change for pagination.
   */
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  /**
   * Define the headers for CSV download.
   */
  const csvHeaders = [
    { label: 'Date & Time', key: 'date' }, // Include both date and time
    { label: 'Customer Name', key: 'customerName' },
    { label: 'Transaction Amount', key: 'amount' },
    { label: 'Payment Type', key: 'paymentType' },
    { label: 'Settlement Amount', key: 'settlementAmount' },
    { label: 'Settlement Status', key: 'settlementStatus' }, // Include settlement status
  ];

  return (
    <div className="settlement-content">
      {/* Header Section */}
      <header className="settlement-header">
        <div className="settlement-title">
          <h2>Settlement</h2>
        </div>
        <div className="date-selector">
          <label htmlFor="start-date" className="visually-hidden">
            Start Date
          </label>
          <input
            type="datetime-local" // Changed to datetime-local to allow both date and time selection
            id="start-date"
            aria-label="Start Date"
            value={startDate}
            onChange={(e) => handleDateChange(e.target.value, endDate)}
          />
          <span> to </span>
          <label htmlFor="end-date" className="visually-hidden">
            End Date
          </label>
          <input
            type="datetime-local" // Changed to datetime-local
            id="end-date"
            aria-label="End Date"
            value={endDate}
            onChange={(e) => handleDateChange(startDate, e.target.value)}
          />
        </div>
      </header>

      {/* Action Bar */}
      <div className="settlement-action-bar">
        <div className="settlement-tabs"></div>

        {/* Search and Download */}
        <div className="settlement-actions">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search settlements"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <FiSearch className="search-icon" />
          </div>
          <CSVLink data={filteredSettlements} headers={csvHeaders} filename="settlements.csv">
            <button className="download-button">
              <FiDownload className="download-icon" />
              Download
            </button>
          </CSVLink>
        </div>
      </div>

      {/* Settlements Table */}
      <div className="settlement-table-container">
        <div className="settlement-table-wrapper">
          <table className="settlement-table">
            <thead>
              <tr>
                <th>Date & Time</th>
                <th>Customer Name</th>
                <th>Transaction Amount</th>
                <th>Payment Type</th>
                <th>Settlement Amount</th>
                <th>Settlement Status</th>
              </tr>
            </thead>
            <tbody>
              {currentSettlements.length > 0 ? (
                currentSettlements.map((settlement, index) => (
                  <tr key={index}>
                    <td>{new Date(settlement.date).toLocaleString()}</td> {/* Show date and time */}
                    <td>{settlement.customerName}</td>
                    <td>₹{settlement.amount.toFixed(2)}</td>
                    <td>{settlement.paymentType}</td>
                    <td>₹{settlement.settlementAmount.toFixed(2)}</td>
                    <td>
                      <span className={`status ${settlement.settlementStatus.toLowerCase()}`}>
                        {settlement.settlementStatus}
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="no-settlements">
                    <div className="no-settlements-message">
                      <p>No settlements found.</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="pagination">
          {[...Array(totalPages || 1)].map((_, idx) => (
            <button
              key={idx + 1}
              className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
              onClick={() => handlePageChange(idx + 1)}
              disabled={filteredSettlements.length === 0}
            >
              {idx + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Settlement;

