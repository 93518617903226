// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import Background from './components/Background';
import Sidebar from './components/Sidebar';
import MainContent from './components/MainContent';
import LoginPage from './components/LoginPage';
import ForgotPassword from './components/ForgotPassword';
import Dashboard from './components/Dashboard';
import Transactions from './components/Transactions';
import Settlement from './components/Settlement';
import RollingReserve from './components/RollingReserve';
import Download from './components/Download';
import Users from './components/Users';
import Profile from './components/Profile';
import AdditionalFunctionality from './components/AdditionalFunctionality'; // Import the new component

/**
 * AuthenticatedLayout Component
 *
 * Renders the Sidebar and MainContent components for authenticated routes.
 * Uses React Router's Outlet to render nested routes within MainContent.
 */
function AuthenticatedLayout() {
  return (
    <>
      <Sidebar />
      <MainContent>
        <Outlet />
      </MainContent>
    </>
  );
}

function App() {
  return (
    <Router>
      <Background>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          {/* Authenticated routes with layout */}
          <Route element={<AuthenticatedLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/payin/transactions" element={<Transactions />} />
            <Route path="/payin/settlement" element={<Settlement />} />
            <Route path="/payin/rolling-reserve" element={<RollingReserve />} />
            <Route path="/payout/additional-functionality" element={<AdditionalFunctionality />} />
            <Route path="/download" element={<Download />} />
            <Route path="/settings/users" element={<Users />} />
            <Route path="/settings/profile" element={<Profile />} />
            {/* Add other authenticated routes here */}
          </Route>
        </Routes>
      </Background>
    </Router>
  );
}

export default App;
